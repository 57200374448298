import moment from 'moment';
import GeneralHelpers from 'components/bricks/helpers/general.helpers';
import placementsSection from '../data/placements';

const start_time = moment().format('YYYY-MM-DD HH:mm:ss');
const end_time = moment().add(5, 'd').format('YYYY-MM-DD HH:mm:ss');

const defaultData = {
    data: {
        settings: {
            targeting: {
                placements: GeneralHelpers.extractPlacementKeys(placementsSection),
                automatic_placements: false
            },
            start_time,
            end_time,
            budget: '50',
            budget_mode: 'LIFETIME',
            bid_amount: '20',
            billing_event: 'IMPRESSIONS',
            frequency_control_specs: {
                event: 'IMPRESSIONS',
                interval_days: '7',
                max_frequency: '1'
            }
        }
    }
};

export default defaultData;
