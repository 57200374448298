/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import { ValidationObject } from 'components/bricks/types/validator.type';

// Ad setup data from the brick object
const brickAdSetup = 'brick.data?.adSetup?';

// Settings data from the brick object
const brickSettings = 'brick.data?.settings?';

// Ad setup is of type 'single'
const isSingleAdSetup = `${brickAdSetup}.type === 'single'`;

// Ad setup is of type 'multi'
const isMultiAdSetup = `${brickAdSetup}.type === 'multi'`;

const parentParent = 'parent.parent';

const validators: ValidationObject = {
    validators: [
        {
            condition: `${brickSettings}.customize_name && (!${brickSettings}.name || !${brickSettings}.name?.length)`,
            message: 'Ad name is required',
            inputIdentifiers: ['meta_ad-name'],
            severity: 'error',
            tabKey: 'settings'
        },
        {
            condition: `!${brickSettings}.adAccountId || !${brickSettings}.adAccountId.length`,
            message: 'Ad account id is required',
            inputIdentifiers: ['meta_ad-ad_account_id'],
            severity: 'error',
            tabKey: 'settings'
        },
        {
            condition: `${brickSettings}.urlParams?.length && !${brickSettings}.urlParams.${ValidateHelpers.getRegexMatch('urlParams')}`,
            message: '',
            inputIdentifiers: ['meta_ad-ad_account_id'],
            severity: 'error',
            tabKey: 'settings'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items.length<2`,
            message: 'Ad with type Multi frame should have at least 2 items',
            inputIdentifiers: ['meta_ad-carousel_cards'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `!brick.data?.adSetup`,
            message: 'There is no data in ad setup',
            inputIdentifiers: ['meta_ad-ad_setup'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${brickAdSetup}.message?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Body can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-message'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: "Body can't start with / ! . ? - * ( ) , ; :"
                }
            ]
        },
        {
            condition: `${brickAdSetup}.message?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Body can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-message'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w\b.*?\b\w\b.*?\b\w\b.*?\b\w\b/ }],
                    severity: 'error',
                    message: "Body can't contain word more than 30 characters"
                }
            ]
        },
        {
            condition: `${brickAdSetup}.message?.match(/\b\w{31,}\b/)`,
            message: "Body can't contain word more than 30 characters",
            inputIdentifiers: ['meta_ad-ad_setup-message'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w{31,}\b/ }],
                    severity: 'error',
                    message: "Body can't contain word more than 30 characters"
                }
            ]
        },
        {
            condition: `${brickAdSetup}.message?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Body can't contain ^~_={}[]|<> these characters",
            inputIdentifiers: ['meta_ad-ad_setup-message'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Body can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${brickAdSetup}.message?.length > 1024`,
            message: 'Body length should be below 1024',
            inputIdentifiers: ['meta_ad-ad_setup-message'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    severity: 'error',
                    max: 1024,
                    message: 'Body length should be below 1024'
                }
            ]
        },
        {
            condition: `!${brickAdSetup}.message?.length || ${brickAdSetup}.message?.length < 1`,
            message: 'Body is required',
            inputIdentifiers: ['meta_ad-ad_setup-message'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Body is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'name'),
            message: 'Title is required',
            inputIdentifiers: ['meta_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 0, 'name'),
            message: 'Title in first frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Title can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Title in first frame can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: "Title can't start with / ! . ? - * ( ) , ; :"
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 1, 'name'),
            message: 'Title in second frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-name-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Title in second frame can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-name-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: "Title can't start with / ! . ? - * ( ) , ; :"
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 2, 'name'),
            message: 'Title in third frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-name-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Title in third frame can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-name-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: "Title can't start with / ! . ? - * ( ) , ; :"
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 3, 'name'),
            message: 'Title in fourth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-name-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Title in fourth frame can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-name-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: "Title can't start with / ! . ? - * ( ) , ; :"
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 4, 'name'),
            message: 'Title in fifth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-name-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Title in fifth frame can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-name-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: "Title can't start with / ! . ? - * ( ) , ; :"
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 5, 'name'),
            message: 'Title in sixth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-name-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Title in sixth frame can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-name-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: "Title can't start with / ! . ? - * ( ) , ; :"
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 6, 'name'),
            message: 'Title in seventh frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-name-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Title in seventh frame can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-name-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: "Title can't start with / ! . ? - * ( ) , ; :"
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 7, 'name'),
            message: 'Title in eigth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-name-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Title in eigth frame can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-name-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: "Title can't start with / ! . ? - * ( ) , ; :"
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 8, 'name'),
            message: 'Title in ninth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-name-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Title in ninth frame can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-name-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: "Title can't start with / ! . ? - * ( ) , ; :"
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 9, 'name'),
            message: 'Title in tenth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-name-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Title is required'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9]?.name?.${ValidateHelpers.getRegexMatch('startsWithSpecialChar')}`,
            message: "Title in tenth frame can't start with / ! . ? - * ( ) , ; :",
            inputIdentifiers: ['meta_ad-ad_setup-name-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /^[\\/!.\?\-*(),;:]/ }],
                    severity: 'error',
                    message: "Title can't start with / ! . ? - * ( ) , ; :"
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.name?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Title can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0]?.name?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Title in first frame can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w\b.*?\b\w\b.*?\b\w\b.*?\b\w\b/ }],
                    severity: 'error',
                    message: "Title can't contain more than 3 one character words"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1]?.name?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Title in second frame can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-name-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w\b.*?\b\w\b.*?\b\w\b.*?\b\w\b/ }],
                    severity: 'error',
                    message: "Title can't contain more than 3 one character words"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2]?.name?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Title in third frame can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-name-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w\b.*?\b\w\b.*?\b\w\b.*?\b\w\b/ }],
                    severity: 'error',
                    message: "Title can't contain more than 3 one character words"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3]?.name?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Title in fourth frame can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-name-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w\b.*?\b\w\b.*?\b\w\b.*?\b\w\b/ }],
                    severity: 'error',
                    message: "Title can't contain more than 3 one character words"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4]?.name?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Title in fifth frame can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-name-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w\b.*?\b\w\b.*?\b\w\b.*?\b\w\b/ }],
                    severity: 'error',
                    message: "Title can't contain more than 3 one character words"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5]?.name?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Title in sixth frame can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-name-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w\b.*?\b\w\b.*?\b\w\b.*?\b\w\b/ }],
                    severity: 'error',
                    message: "Title can't contain more than 3 one character words"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6]?.name?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Title in seventh frame can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-name-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w\b.*?\b\w\b.*?\b\w\b.*?\b\w\b/ }],
                    severity: 'error',
                    message: "Title can't contain more than 3 one character words"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7]?.name?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Title in eigth frame can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-name-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w\b.*?\b\w\b.*?\b\w\b.*?\b\w\b/ }],
                    severity: 'error',
                    message: "Title can't contain more than 3 one character words"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8]?.name?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Title in ninth frame can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-name-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w\b.*?\b\w\b.*?\b\w\b.*?\b\w\b/ }],
                    severity: 'error',
                    message: "Title can't contain more than 3 one character words"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9]?.name?.${ValidateHelpers.getRegexMatch('maxThreeOneCharWords')}`,
            message: "Title in tenth frame can't contain more than 3 one character words",
            inputIdentifiers: ['meta_ad-ad_setup-name-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\b\w\b.*?\b\w\b.*?\b\w\b.*?\b\w\b/ }],
                    severity: 'error',
                    message: "Title can't contain more than 3 one character words"
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: 'Title contain ^ ~ _ = { } [ ] | < > these characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Title in first frame can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['meta_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Title can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Title in second frame can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['meta_ad-ad_setup-name-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Title can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Title in third frame can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['meta_ad-ad_setup-name-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Title can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Title in fourth frame can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['meta_ad-ad_setup-name-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Title can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Title in fifth frame can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['meta_ad-ad_setup-name-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Title can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Title in sixth frame can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['meta_ad-ad_setup-name-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Title can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Title in seventh frame can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['meta_ad-ad_setup-name-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Title can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Title in eigth frame can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['meta_ad-ad_setup-name-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Title can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Title in ninth frame can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['meta_ad-ad_setup-name-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Title can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9]?.name?.${ValidateHelpers.getRegexMatch('restrictedSpecialChar')}`,
            message: "Title in tenth frame can't contain ^ ~ _ = { } [ ] | < > these characters",
            inputIdentifiers: ['meta_ad-ad_setup-name-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[\^~_={}[\]|<>]/g }],
                    severity: 'error',
                    message: "Title can't contain ^ ~ _ = { } [ ] | < > these characters"
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0] && ${brickAdSetup}.items[0].name?.length>25`,
            message: 'Title should be below 25 characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0] && ${brickAdSetup}.items[0].name?.length>25`,
            message: 'Title in first frame should be below 25 characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 25,
                    severity: 'error',
                    message: 'Title should be below 25 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1] && ${brickAdSetup}.items[1].name?.length>25`,
            message: 'Title in second frame should be below 25 characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 25,
                    severity: 'error',
                    message: 'Title should be below 25 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2] && ${brickAdSetup}.items[2].name?.length>25`,
            message: 'Title in third frame should be below 25 characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 25,
                    severity: 'error',
                    message: 'Title should be below 25 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3] && ${brickAdSetup}.items[3].name?.length>25`,
            message: 'Title in fourth frame should be below 25 characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 25,
                    severity: 'error',
                    message: 'Title should be below 25 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4] && ${brickAdSetup}.items[4].name?.length>25`,
            message: 'Title in fifth frame should be below 25 characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 25,
                    severity: 'error',
                    message: 'Title should be below 25 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5] && ${brickAdSetup}.items[5].name?.length>25`,
            message: 'Title in sixth frame should be below 25 characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 25,
                    severity: 'error',
                    message: 'Title should be below 25 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6] && ${brickAdSetup}.items[6].name?.length>25`,
            message: 'Title in seventh frame should be below 25 characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 25,
                    severity: 'error',
                    message: 'Title should be below 25 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7] && ${brickAdSetup}.items[7].name?.length>25`,
            message: 'Title in eigth frame should be below 25 characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 25,
                    severity: 'error',
                    message: 'Title should be below 25 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8] && ${brickAdSetup}.items[8].name?.length>25`,
            message: 'Title in ninth frame should be below 25 characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 25,
                    severity: 'error',
                    message: 'Title should be below 25 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9] && ${brickAdSetup}.items[9].name?.length>25`,
            message: 'Title in tenth frame should be below 25 characters',
            inputIdentifiers: ['meta_ad-ad_setup-name-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 25,
                    severity: 'error',
                    message: 'Title should be below 25 characters'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'description'),
            message: 'Description is required',
            inputIdentifiers: ['meta_ad-ad_setup-description-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 0, 'description'),
            message: 'Description in first frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-description-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 1, 'description'),
            message: 'Description in second frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-description-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 2, 'description'),
            message: 'Description in third frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-description-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 3, 'description'),
            message: 'Description in fourth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-description-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 4, 'description'),
            message: 'Description in fifth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-description-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 5, 'description'),
            message: 'Description in sixth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-description-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 6, 'description'),
            message: 'Description in seventh frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-description-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 7, 'description'),
            message: 'Description in eigth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-description-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 8, 'description'),
            message: 'Description in ninth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-description-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 9, 'description'),
            message: 'Description in tenth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-description-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Description is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'link'),
            message: 'Link is required',
            inputIdentifiers: ['meta_ad-ad_setup-link-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 0, 'link'),
            message: 'Link in first frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-link-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 1, 'link'),
            message: 'Link in second frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-link-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 2, 'link'),
            message: 'Link in third frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-link-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 3, 'link'),
            message: 'Link in fourth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-link-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 4, 'link'),
            message: 'Link in fifth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-link-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 5, 'link'),
            message: 'Link in sixth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-link-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 6, 'link'),
            message: 'Link in seventh frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-link-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 7, 'link'),
            message: 'Link in eigth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-link-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 8, 'link'),
            message: 'Link in ninth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-link-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('multi', 9, 'link'),
            message: 'Link in tenth frame is required',
            inputIdentifiers: ['meta_ad-ad_setup-link-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.description?.length > 255`,
            message: 'Description should be below 255 characters',
            inputIdentifiers: ['meta_ad-ad_setup-description-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0]?.description?.length > 255`,
            message: 'Description in first frame should be below 255 characters',
            inputIdentifiers: ['meta_ad-ad_setup-description-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 255,
                    severity: 'error',
                    message: 'Description should be below 255 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1]?.description?.length > 255`,
            message: 'Description in second frame should be below 255 characters',
            inputIdentifiers: ['meta_ad-ad_setup-description-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 255,
                    severity: 'error',
                    message: 'Description should be below 255 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2]?.description?.length > 255`,
            message: 'Description in third frame should be below 255 characters',
            inputIdentifiers: ['meta_ad-ad_setup-description-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 255,
                    severity: 'error',
                    message: 'Description should be below 255 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3]?.description?.length > 255`,
            message: 'Description in fourth frame should be below 255 characters',
            inputIdentifiers: ['meta_ad-ad_setup-description-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 255,
                    severity: 'error',
                    message: 'Description should be below 255 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4]?.description?.length > 255`,
            message: 'Description in fifth frame should be below 255 characters',
            inputIdentifiers: ['meta_ad-ad_setup-description-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 255,
                    severity: 'error',
                    message: 'Description should be below 255 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5]?.description?.length > 255`,
            message: 'Description in sixth frame should be below 255 characters',
            inputIdentifiers: ['meta_ad-ad_setup-description-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 255,
                    severity: 'error',
                    message: 'Description should be below 255 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6]?.description?.length > 255`,
            message: 'Description in seventh frame should be below 255 characters',
            inputIdentifiers: ['meta_ad-ad_setup-description-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 255,
                    severity: 'error',
                    message: 'Description should be below 255 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7]?.description?.length > 255`,
            message: 'Description in eigth frame should be below 255 characters',
            inputIdentifiers: ['meta_ad-ad_setup-description-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 255,
                    severity: 'error',
                    message: 'Description should be below 255 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8]?.description?.length > 255`,
            message: 'Description in ninth frame should be below 255 characters',
            inputIdentifiers: ['meta_ad-ad_setup-description-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 255,
                    severity: 'error',
                    message: 'Description should be below 255 characters'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9]?.description?.length > 255`,
            message: 'Description in tenth frame should be below 255 characters',
            inputIdentifiers: ['meta_ad-ad_setup-description-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    max: 255,
                    severity: 'error',
                    message: 'Description should be below 255 characters'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.link && !${brickAdSetup}.items[0].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link is not a valid URL',
            inputIdentifiers: ['meta_ad-ad_setup-link-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[0]?.link && !${brickAdSetup}.items[0].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in first frame is not a valid URL',
            inputIdentifiers: ['meta_ad-ad_setup-link-0'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(https?:\/\/)?(?:www\.)\S+\.\S+\b/ }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[1]?.link && !${brickAdSetup}.items[1].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in second frame is not a valid URL',
            inputIdentifiers: ['meta_ad-ad_setup-link-1'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[2]?.link && !${brickAdSetup}.items[2].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in third frame is not a valid URL',
            inputIdentifiers: ['meta_ad-ad_setup-link-2'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[3]?.link && !${brickAdSetup}.items[3].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in fourth frame is not a valid URL',
            inputIdentifiers: ['meta_ad-ad_setup-link-3'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[4]?.link && !${brickAdSetup}.items[4].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in fifth frame is not a valid URL',
            inputIdentifiers: ['meta_ad-ad_setup-link-4'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[5]?.link && !${brickAdSetup}.items[5].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in sixth frame is not a valid URL',
            inputIdentifiers: ['meta_ad-ad_setup-link-5'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[6]?.link && !${brickAdSetup}.items[6].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in seventh frame is not a valid URL',
            inputIdentifiers: ['meta_ad-ad_setup-link-6'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[7]?.link && !${brickAdSetup}.items[7].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in eigth frame is not a valid URL',
            inputIdentifiers: ['meta_ad-ad_setup-link-7'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[8]?.link && !${brickAdSetup}.items[8].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in ninth frame is not a valid URL',
            inputIdentifiers: ['meta_ad-ad_setup-link-8'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `${isMultiAdSetup} && ${brickAdSetup}.items?.[9]?.link && !${brickAdSetup}.items[9].link?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link in tenth frame is not a valid URL',
            inputIdentifiers: ['meta_ad-ad_setup-link-9'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(?:https?:\/\/)?(?:www\.)\S+\.\S+\b/gm }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        },
        {
            condition: `parent && ${parentParent} && ${parentParent}.data.settings.objective === "OUTCOME_AWARENESS" && (!${brickAdSetup}.cta || !['APPLY_NOW','BOOK_NOW','DOWNLOAD','SHOP_NOW','SIGN_UP','WHATSAPP_MESSAGE','FIND_YOUR_GROUPS','SEND_MESSAGE','SUBSCRIBE','ORDER_NOW','WATCH_MORE','LEARN_MORE','GET_DIRECTIONS','CONTACT_US','GET_QUOTE','GET_SHOWTIMES','LISTEN_NOW','REQUEST_TIME','DIAL_CODE','SEE_MENU'].includes(${brickAdSetup}.cta))`,
            message:
                'Call to action for objective Outcome Awareness should be one of Apply now, Book now, Download, Shop now, Sign up, Whatsapp message, Find your groups, Send message, Subscribe, Order now, Watch more, Learn more, Get directions, Contact us, Get quote, Get showtimes, Listen now, Request time, Dial code, See menu',
            inputIdentifiers: ['meta_ad-ad_setup-cta'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `parent && ${parentParent} && ${parentParent}.data.settings.objective === "OUTCOME_TRAFFIC" && (!${brickAdSetup}.cta || ['SHOP_NOW','LEARN_MORE','SIGN_UP','DOWNLOAD','APPLY_NOW','SEND_MESSAGE','CHECK_AVAILABILITY','GET_ACCESS','GET_TICKETS','GET_PROMOTIONS','GET_UPDATES','REMIND_ME','ORDER_NOW','GET_OFFER','SUBSCRIBE','BOOK_NOW','LISTEN_NOW','WHATSAPP_MESSAGE','WATCH_MORE','SEE_MENU','REQUEST_TIME', 'BUY_TICKETS','CONTACT_US','CALL_NOW','GET_SHOWTIMES'].includes(${brickAdSetup}.cta))`,
            message:
                'Call to action for objective Outcome Traffic should be one of Show now, Learn more , Sign up, Download, Apply now, Send message, Check availability, Get access, Get tickets, Get directions, Get updates, Remind me, Order now, Get offer,  Subscribe, Book now, Listen now, Whatsapp message, Watch more, See menu, Request time, Buy tickets, Contact us, Call now, Get showtimes',
            inputIdentifiers: ['meta_ad-ad_setup-cta'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateFrameCreativeValidator('single'),
            message: 'There are no creatives',
            inputIdentifiers: ['meta_ad-ad_setup-creative_input-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateFrameCreativeValidator('multi'),
            message: 'There are no creatives in the first frame',
            inputIdentifiers: ['meta_ad-ad_setup-creative_input-0'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateFrameCreativeValidator('multi', 1),
            message: 'There are no creatives in the second frame',
            inputIdentifiers: ['meta_ad-ad_setup-creative_input-1'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateFrameCreativeValidator('multi', 2),
            message: 'There are no creatives in the third frame',
            inputIdentifiers: ['meta_ad-ad_setup-creative_input-2'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateFrameCreativeValidator('multi', 3),
            message: 'There are no creatives in the fourth frame',
            inputIdentifiers: ['meta_ad-ad_setup-creative_input-3'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateFrameCreativeValidator('multi', 4),
            message: 'There are no creatives in the fifth frame',
            inputIdentifiers: ['meta_ad-ad_setup-creative_input-4'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateFrameCreativeValidator('multi', 5),
            message: 'There are no creatives in the sixth frame',
            inputIdentifiers: ['meta_ad-ad_setup-creative_input-5'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateFrameCreativeValidator('multi', 6),
            message: 'There are no creatives in the seventh frame',
            inputIdentifiers: ['meta_ad-ad_setup-creative_input-6'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateFrameCreativeValidator('multi', 7),
            message: 'There are no creatives in the eigth frame',
            inputIdentifiers: ['meta_ad-ad_setup-creative_input-7'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateFrameCreativeValidator('multi', 8),
            message: 'There are no creatives in the ninth frame',
            inputIdentifiers: ['meta_ad-ad_setup-creative_input-8'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: ValidateHelpers.generateFrameCreativeValidator('multi', 9),
            message: 'There are no creatives in the tenth frame',
            inputIdentifiers: ['meta_ad-ad_setup-creative_input-9'],
            severity: 'error',
            tabKey: 'adSetup'
        },

        // Facebook Feed
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'facebook-feed'),
            message: 'Facebook feed does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-feed-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-feed'),
            message: 'Facebook feed in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-feed-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-feed', 1),
            message: 'Facebook feed in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-feed-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-feed', 2),
            message: 'Facebook feed in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-feed-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-feed', 3),
            message: 'Facebook feed in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-feed-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-feed', 4),
            message: 'Facebook feed in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-feed-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-feed', 5),
            message: 'Facebook feed in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-feed-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-feed', 6),
            message: 'Facebook feed in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-feed-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-feed', 7),
            message: 'Facebook feed in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-feed-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-feed', 8),
            message: 'Facebook feed in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-feed-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-feed', 9),
            message: 'Facebook feed in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-feed-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Instagram Profile Feed
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'instagram-profile_feed'),
            message: 'Instagram profile feed does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-profile_feed-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-profile_feed'),
            message: 'Instagram profile feed in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-profile_feed-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-profile_feed', 1),
            message: 'Instagram profile feed in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-profile_feed-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-profile_feed', 2),
            message: 'Instagram profile feed in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-profile_feed-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-profile_feed', 3),
            message: 'Instagram profile feed in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-profile_feed-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-profile_feed', 4),
            message: 'Instagram profile feed in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-profile_feed-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-profile_feed', 5),
            message: 'Instagram profile feed in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-profile_feed-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-profile_feed', 6),
            message: 'Instagram profile feed in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-profile_feed-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-profile_feed', 7),
            message: 'Instagram profile feed in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-profile_feed-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-profile_feed', 8),
            message: 'Instagram profile feed in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-profile_feed-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-profile_feed', 9),
            message: 'Instagram profile feed in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-profile_feed-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Instagram Explore
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'instagram-explore'),
            message: 'Instagram explore does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore'),
            message: 'Instagram explore in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore', 1),
            message: 'Instagram explore in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore', 2),
            message: 'Instagram explore in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore', 3),
            message: 'Instagram explore in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore', 4),
            message: 'Instagram explore in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore', 5),
            message: 'Instagram explore in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore', 6),
            message: 'Instagram explore in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore', 7),
            message: 'Instagram explore in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore', 8),
            message: 'Instagram explore in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore', 9),
            message: 'Instagram explore in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Facebook Video Feeds
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'facebook-video_feeds'),
            message: 'Facebook video feeds does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-video_feeds-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-video_feeds'),
            message: 'Facebook video feeds in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-video_feeds-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-video_feeds', 1),
            message: 'Facebook video feeds in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-video_feeds-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-video_feeds', 2),
            message: 'Facebook video feeds in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-video_feeds-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-video_feeds', 3),
            message: 'Facebook video feeds in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-video_feeds-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-video_feeds', 4),
            message: 'Facebook video feeds in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-video_feeds-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-video_feeds', 5),
            message: 'Facebook video feeds in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-video_feeds-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-video_feeds', 6),
            message: 'Facebook video feeds in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-video_feeds-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-video_feeds', 7),
            message: 'Facebook video feeds in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-video_feeds-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-video_feeds', 8),
            message: 'Facebook video feeds in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-video_feeds-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-video_feeds', 9),
            message: 'Facebook video feeds in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-video_feeds-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Facebook Instream Video
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'facebook-instream_video'),
            message: 'Facebook instream video does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-instream_video-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-instream_video'),
            message: 'Facebook instream video in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-instream_video-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-instream_video', 1),
            message: 'Facebook instream video in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-instream_video-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-instream_video', 2),
            message: 'Facebook instream video in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-instream_video-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-instream_video', 3),
            message: 'Facebook instream video in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-instream_video-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-instream_video', 4),
            message: 'Facebook instream video in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-instream_video-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-instream_video', 5),
            message: 'Facebook instream video in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-instream_video-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-instream_video', 6),
            message: 'Facebook instream video in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-instream_video-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-instream_video', 7),
            message: 'Facebook instream video in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-instream_video-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-instream_video', 8),
            message: 'Facebook instream video in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-instream_video-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-instream_video', 9),
            message: 'Facebook instream video in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-instream_video-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Facebook Search
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'facebook-search'),
            message: 'Facebook search does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-search-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-search'),
            message: 'Facebook search in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-search-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-search', 1),
            message: 'Facebook search in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-search-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-search', 2),
            message: 'Facebook search in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-search-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-search', 3),
            message: 'Facebook search in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-search-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-search', 4),
            message: 'Facebook search in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-search-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-search', 5),
            message: 'Facebook search in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-search-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-search', 6),
            message: 'Facebook search in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-search-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-search', 7),
            message: 'Facebook search in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-search-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-search', 8),
            message: 'Facebook search in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-search-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-search', 9),
            message: 'Facebook search in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-search-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Facebook Marketplace
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'facebook-marketplace'),
            message: 'Facebook marketplace does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-marketplace-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-marketplace'),
            message: 'Facebook marketplace in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-marketplace-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-marketplace', 1),
            message: 'Facebook marketplace in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-marketplace-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-marketplace', 2),
            message: 'Facebook marketplace in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-marketplace-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-marketplace', 3),
            message: 'Facebook marketplace in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-marketplace-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-marketplace', 4),
            message: 'Facebook marketplace in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-marketplace-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-marketplace', 5),
            message: 'Facebook marketplace in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-marketplace-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-marketplace', 6),
            message: 'Facebook marketplace in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-marketplace-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-marketplace', 7),
            message: 'Facebook marketplace in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-marketplace-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-marketplace', 8),
            message: 'Facebook marketplace in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-marketplace-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-marketplace', 9),
            message: 'Facebook marketplace in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-marketplace-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Facebook Right Hand Column
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'facebook-right_hand_column'),
            message: 'Facebook right hand column does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-right_hand_column-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-right_hand_column'),
            message: 'Facebook right hand column in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-right_hand_column-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-right_hand_column', 1),
            message: 'Facebook right hand column in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-right_hand_column-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-right_hand_column', 2),
            message: 'Facebook right hand column in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-right_hand_column-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-right_hand_column', 3),
            message: 'Facebook right hand column in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-right_hand_column-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-right_hand_column', 4),
            message: 'Facebook right hand column in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-right_hand_column-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-right_hand_column', 5),
            message: 'Facebook right hand column in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-right_hand_column-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-right_hand_column', 6),
            message: 'Facebook right hand column in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-right_hand_column-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-right_hand_column', 7),
            message: 'Facebook right hand column in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-right_hand_column-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-right_hand_column', 8),
            message: 'Facebook right hand column in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-right_hand_column-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-right_hand_column', 9),
            message: 'Facebook right hand column in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-right_hand_column-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Facebook Story
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'facebook-story'),
            message: 'Facebook story does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-story-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-story'),
            message: 'Facebook story in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-story-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-story', 1),
            message: 'Facebook story in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-story-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-story', 2),
            message: 'Facebook story in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-story-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-story', 3),
            message: 'Facebook story in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-story-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-story', 4),
            message: 'Facebook story in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-story-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-story', 5),
            message: 'Facebook story in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-story-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-story', 6),
            message: 'Facebook story in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-story-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-story', 7),
            message: 'Facebook story in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-story-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-story', 8),
            message: 'Facebook story in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-story-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-story', 9),
            message: 'Facebook story in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-story-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Instagram Story
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'instagram-story'),
            message: 'Instagram story does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-story-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-story'),
            message: 'Instagram story in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-story-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-story', 1),
            message: 'Instagram story in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-story-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-story', 2),
            message: 'Instagram story in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-story-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-story', 3),
            message: 'Instagram story in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-story-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-story', 4),
            message: 'Instagram story in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-story-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-story', 5),
            message: 'Instagram story in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-story-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-story', 6),
            message: 'Instagram story in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-story-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-story', 7),
            message: 'Instagram story in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-story-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-story', 8),
            message: 'Instagram story in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-story-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-story', 9),
            message: 'Instagram story in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-story-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        //Facebook Profile Feed
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'facebook-profile_feed'),
            message: 'Facebook profile feed does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-profile_feed-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-profile_feed'),
            message: 'Facebook profile feed in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-profile_feed-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-profile_feed', 1),
            message: 'Facebook profile feed in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-profile_feed-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-profile_feed', 2),
            message: 'Facebook profile feed in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-profile_feed-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-profile_feed', 3),
            message: 'Facebook profile feed in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-profile_feed-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-profile_feed', 4),
            message: 'Facebook profile feed in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-profile_feed-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-profile_feed', 5),
            message: 'Facebook profile feed in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-profile_feed-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-profile_feed', 6),
            message: 'Facebook profile feed in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-profile_feed-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-profile_feed', 7),
            message: 'Facebook profile feed in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-profile_feed-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-profile_feed', 8),
            message: 'Facebook profile feed in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-profile_feed-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-profile_feed', 9),
            message: 'Facebook profile feed in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-profile_feed-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Instagram Explore Home
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'instagram-explore_home'),
            message: 'Instagram explore home does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore_home-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore_home'),
            message: 'Instagram explore home in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore_home-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore_home', 1),
            message: 'Instagram explore home in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore_home-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore_home', 2),
            message: 'Instagram explore home in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore_home-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore_home', 3),
            message: 'Instagram explore home in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore_home-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore_home', 4),
            message: 'Instagram explore home in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore_home-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore_home', 5),
            message: 'Instagram explore home in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore_home-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore_home', 6),
            message: 'Instagram explore home in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore_home-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore_home', 7),
            message: 'Instagram explore home in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore_home-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore_home', 8),
            message: 'Instagram explore home in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore_home-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-explore_home', 9),
            message: 'Instagram explore home in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-explore_home-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Instagram Search
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'instagram-ig_search'),
            message: 'Instagram search does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-ig_search-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-ig_search'),
            message: 'Instagram search in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-ig_search-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-ig_search', 1),
            message: 'Instagram search in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-ig_search-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-ig_search', 2),
            message: 'Instagram search in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-ig_search-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-ig_search', 3),
            message: 'Instagram search in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-ig_search-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-ig_search', 4),
            message: 'Instagram search in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-ig_search-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-ig_search', 5),
            message: 'Instagram search in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-ig_search-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-ig_search', 6),
            message: 'Instagram search in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-ig_search-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-ig_search', 7),
            message: 'Instagram search in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-ig_search-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-ig_search', 8),
            message: 'Instagram search in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-ig_search-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-ig_search', 9),
            message: 'Instagram search in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-ig_search-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Ads On Reels
        // {
        //     condition: ValidateHelpers.generatePlacementValidationCondition('single', 'facebook-ads_on_reels'),
        //     message: 'Ad on reels does not have an assigned creative',
        //     inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-ads_on_reels-0'],
        //     tabKey: 'adSetup',
        //     severity: 'warning'
        // },
        // {
        //     condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-ads_on_reels'),
        //     message: 'Ad on reels in first frame does not have an assigned creative',
        //     inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-ads_on_reels-0'],
        //     tabKey: 'adSetup',
        //     severity: 'warning'
        // },
        // {
        //     condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-ads_on_reels', 1),
        //     message: 'Ad on reels in second frame does not have an assigned creative',
        //     inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-ads_on_reels-1'],
        //     tabKey: 'adSetup',
        //     severity: 'warning'
        // },
        // {
        //     condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-ads_on_reels', 2),
        //     message: 'Ad on reels in third frame does not have an assigned creative',
        //     inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-ads_on_reels-2'],
        //     tabKey: 'adSetup',
        //     severity: 'warning'
        // },
        // {
        //     condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-ads_on_reels', 3),
        //     message: 'Ad on reels in fourth frame does not have an assigned creative',
        //     inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-ads_on_reels-3'],
        //     tabKey: 'adSetup',
        //     severity: 'warning'
        // },
        // {
        //     condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-ads_on_reels', 4),
        //     message: 'Ad on reels in fifth frame does not have an assigned creative',
        //     inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-ads_on_reels-4'],
        //     tabKey: 'adSetup',
        //     severity: 'warning'
        // },
        // {
        //     condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-ads_on_reels', 5),
        //     message: 'Ad on reels in sixth frame does not have an assigned creative',
        //     inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-ads_on_reels-5'],
        //     tabKey: 'adSetup',
        //     severity: 'warning'
        // },
        // {
        //     condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-ads_on_reels', 6),
        //     message: 'Ad on reels in seventh frame does not have an assigned creative',
        //     inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-ads_on_reels-6'],
        //     tabKey: 'adSetup',
        //     severity: 'warning'
        // },
        // {
        //     condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-ads_on_reels', 7),
        //     message: 'Ad on reels in eight frame does not have an assigned creative',
        //     inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-ads_on_reels-7'],
        //     tabKey: 'adSetup',
        //     severity: 'warning'
        // },
        // {
        //     condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-ads_on_reels', 8),
        //     message: 'Ad on reels in ninth frame does not have an assigned creative',
        //     inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-ads_on_reels-8'],
        //     tabKey: 'adSetup',
        //     severity: 'warning'
        // },
        // {
        //     condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-ads_on_reels', 9),
        //     message: 'Ad on reels in tenth frame does not have an assigned creative',
        //     inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-ads_on_reels-9'],
        //     tabKey: 'adSetup',
        //     severity: 'warning'
        // },

        // Facebook Reels
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'facebook-facebook_reels'),
            message: 'Facebook reel does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-facebook_reels-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-facebook_reels'),
            message: 'Facebook reel in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-facebook_reels-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-facebook_reels', 1),
            message: 'Facebook reel in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-facebook_reels-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-facebook_reels', 2),
            message: 'Facebook reel in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-facebook_reels-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-facebook_reels', 3),
            message: 'Facebook reel in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-facebook_reels-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-facebook_reels', 4),
            message: 'Facebook reel in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-facebook_reels-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-facebook_reels', 5),
            message: 'Facebook reel in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-facebook_reels-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-facebook_reels', 6),
            message: 'Facebook reel in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-facebook_reels-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-facebook_reels', 7),
            message: 'Facebook reel in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-facebook_reels-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-facebook_reels', 8),
            message: 'Facebook reel in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-facebook_reels-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'facebook-facebook_reels', 9),
            message: 'Facebook reel in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-facebook-facebook_reels-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Instagram Reels
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'instagram-reels'),
            message: 'Instagram reel does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-reels-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-reels'),
            message: 'Instagram reel in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-reels-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-reels', 1),
            message: 'Instagram reel in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-reels-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-reels', 2),
            message: 'Instagram reel in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-reels-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-reels', 3),
            message: 'Instagram reel in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-reels-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-reels', 4),
            message: 'Instagram reel in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-reels-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-reels', 5),
            message: 'Instagram reel in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-reels-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-reels', 6),
            message: 'Instagram reel in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-reels-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-reels', 7),
            message: 'Instagram reel in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-reels-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-reels', 8),
            message: 'Instagram reel in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-reels-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-reels', 9),
            message: 'Instagram reel in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-reels-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        },

        // Instagram Feed
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('single', 'instagram-stream'),
            message: 'Instagram feed does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-stream-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-stream'),
            message: 'Instagram feed in first frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-stream-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-stream', 1),
            message: 'Instagram feed in second frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-stream-1'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-stream', 2),
            message: 'Instagram feed in third frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-stream-2'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-stream', 3),
            message: 'Instagram feed in fourth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-stream-3'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-stream', 4),
            message: 'Instagram feed in fifth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-stream-4'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-stream', 5),
            message: 'Instagram feed in sixth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-stream-5'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-stream', 6),
            message: 'Instagram feed in seventh frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-stream-6'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-stream', 7),
            message: 'Instagram feed in eight frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-stream-7'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-stream', 8),
            message: 'Instagram feed in ninth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-stream-8'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generatePlacementValidationCondition('multi', 'instagram-stream', 9),
            message: 'Instagram feed in tenth frame does not have an assigned creative',
            inputIdentifiers: ['meta_ad-ad_setup-placements-instagram-stream-9'],
            tabKey: 'adSetup',
            severity: 'warning'
        }
    ]
};

export default validators;
